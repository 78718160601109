import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { WordCloudData, CommitmentEntry } from '../types/wordCloud';


const WordCloudVisualization = () => {
  const [wordCloudData, setWordCloudData] = useState<WordCloudData[]>([]);
  const [commitments, setCommitments] = useState<CommitmentEntry[]>([]);
  const [eventSource, setEventSource] = useState<EventSource | null>(null);
  
  
  const processAndDisplayCloud = () => {
    const intentCounts: { [key: string]: number } = {};
    
    commitments.forEach(entry => {
      if (entry.intent) {
        intentCounts[entry.intent] = (intentCounts[entry.intent] || 0) + 1;
      }
    });

    const cloudData = Object.entries(intentCounts).map(([text, value]) => ({
      text,
      value
    }));

    setWordCloudData(cloudData);
  };

  const handleButtonClick = () => {
    if (eventSource) {
      eventSource.close();
    }

    const newEventSource = new EventSource(`${process.env.REACT_APP_GPS_TREE}/api/stream`);

    newEventSource.onmessage = (event) => {
      const newData = JSON.parse(event?.data);
      setCommitments((prevData) => [...prevData, ...newData]);
    };

    newEventSource.addEventListener('initial', (event) => {
      const initialData = JSON.parse(event?.data);
      setCommitments(initialData);
    });

    newEventSource.addEventListener('entry', (event) => {
      const newEntry = JSON.parse(event?.data);
      setCommitments((prevData) => [...prevData, newEntry]);
    });

    newEventSource.onerror = (error) => {
      console.error('error:', error);
      newEventSource.close();
    };

    setEventSource(newEventSource);
  };

  useEffect(() => {
    processAndDisplayCloud();
  }, [commitments]);

  // Calculate font size based on frequency with larger range
  const getFontSize = (frequency: number) => {
    const baseSize = 14;
    const maxSize = 64;
    return Math.min(baseSize + (frequency * 12), maxSize);
  };

  // Generate random pastel color
  const getPastelColor = (text: string) => {
    const hue = Math.abs(text.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)) % 360;
    return `hsl(${hue}, 70%, 65%)`;
  };

  // Generate random position within container
  const getRandomPosition = (index: number, total: number) => {
    const angle = (index / total) * 2 * Math.PI;
    const radius = Math.random() * 150 + 50; // Random radius between 50 and 200
    const x = Math.cos(angle) * radius;
    const y = Math.sin(angle) * radius;
    return { x, y };
  };

  // Generate random rotation
  const getRandomRotation = () => {
    return Math.random() * 40 - 20; // Random rotation between -20 and 20 degrees
  };

  return (
    <div className="min-h-screen bg-white p-8">
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Collective Commitments
          </h1>
          <p className="text-xl text-gray-600 mb-8">
            A visualization of our shared intentions
          </p>
          
          <button
            onClick={handleButtonClick}
            // disabled={commitments.length === 0}
            className="px-6 py-3 bg-blue-600 text-white rounded-lg font-medium
                     disabled:opacity-50 disabled:cursor-not-allowed
                     hover:bg-blue-700 transition-colors"
          >
            Generate Word Cloud
          </button>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-8 relative" style={{ height: '700px' }}>
          <div className="absolute inset-0 flex items-center justify-center">
            {wordCloudData.length > 0 ? (
              <div className="relative w-full h-full">
                {wordCloudData.map((word, index) => {
                  const position = getRandomPosition(index, wordCloudData.length);
                  return (
                    <motion.div
                      key={`${word.text}-${index}`}
                      initial={{ opacity: 0, scale: 0 }}
                      animate={{ 
                        opacity: 1, 
                        scale: 1,
                        x: position.x,
                        y: position.y,
                        rotate: getRandomRotation()
                      }}
                      transition={{ 
                        duration: 0.8,
                        delay: index * 0.1,
                        type: "spring",
                        stiffness: 100
                      }}
                      className="absolute text-center transform -translate-x-1/2 -translate-y-1/2"
                      style={{
                        fontSize: `${getFontSize(word.value)}px`,
                        fontWeight: 300 + (word.value * 100),
                        color: getPastelColor(word.text),
                        left: '50%',
                        top: '50%',
                        cursor: 'default',
                        textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {word.text}
                    </motion.div>
                  );
                })}
              </div>
            ) : (
              <div className="text-gray-500 text-center">
                {commitments.length === 0 ? (
                  'No commitments yet. Add some commitments to generate the word cloud.'
                ) : (
                  'Click "Generate Word Cloud" to analyze commitments.'
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WordCloudVisualization;