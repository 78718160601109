import React, { useState } from 'react';
import { usePartnership } from '../context/PartnershipContext';
import { PartnershipBehavior } from '../types/partnership';
import { motion } from 'framer-motion';
import SelectionScreen from './SelectionScreen';
import { CommitmentEntry } from '../context/PartnershipContext';
import { DeloitteColors } from '../constants/colors';
import axios from 'axios';

const ThankYouPopup: React.FC<{ onClose: () => void }> = ({ onClose }) => (
  <motion.div
    initial={{ opacity: 0, scale: 0.5 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.5 }}
    className="fixed inset-0 flex items-center justify-center z-50"
  >
    <div className="fixed inset-0 bg-black bg-opacity-50" />
    <div 
      className="bg-white rounded-lg p-8 relative z-10 max-w-md mx-4"
      style={{ backgroundColor: DeloitteColors.white }}
    >
      <h2 
        className="text-2xl font-bold mb-4"
        style={{ color: DeloitteColors.coolGray11 }}
      >
        Thank You!
      </h2>
      <p 
        className="text-lg mb-4"
        style={{ color: DeloitteColors.coolGray7 }}
      >
        Your contribution to our partnership tree has been recorded.
      </p>
      <motion.div
        className="w-16 h-16 mx-auto mb-4"
        animate={{ 
          scale: [1, 1.2, 1],
          rotate: [0, 360, 360]
        }}
        transition={{ 
          duration: 1.5,
          ease: "easeInOut",
          times: [0, 0.5, 1],
          repeat: Infinity
        }}
      >
        <svg viewBox="0 0 24 24" fill="none" stroke={DeloitteColors.deloitteGreen} strokeWidth="2">
          <path d="M20 6L9 17L4 12" />
        </svg>
      </motion.div>
    </div>
  </motion.div>
);

const PartnershipApp: React.FC = () => {
  const { setSelections } = usePartnership();
  // const [showThankYou, setShowThankYou] = useState(false);
  const [selectionKey, setSelectionKey] = useState(0);

  const handleSubmit = async (email: string, behavior: PartnershipBehavior, commitments: string | { type: 'other', text: string }) => {
    try {
      const newCommitment: CommitmentEntry = {
        id: Date.now().toString(),
        email,
        behavior,
        commitments,
        intent: undefined
      };

      const response = await axios.post(`${process.env.REACT_APP_GPS_TREE}/api/entries`, newCommitment);
      
      if (response.status === 201 || response.status === 200) {
        console.log('Commitment added:', response.data);

      setSelections((prev: CommitmentEntry[]) => [...prev, newCommitment]);
      // setShowThankYou(true);

      setTimeout(() => {
        // setShowThankYou(false);
        setSelectionKey(prev => prev + 1);
      }, 3000);
    }
    } catch (err) {
      console.error('Failed to add commitment:', err);
    }
  };

  return (
    <div className="min-h-screen relative">
      <SelectionScreen key={selectionKey} onSubmit={handleSubmit} />
      

      {/* <AnimatePresence>
        {showThankYou && <ThankYouPopup onClose={() => setShowThankYou(false)} />}
      </AnimatePresence> */}
    </div>
  );
};

export default PartnershipApp;