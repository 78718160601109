import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PartnershipProvider, usePartnership } from './context/PartnershipContext';
import PartnershipApp from './components/PartnershipApp';
import NewTreeVisualization from './components/newTreeVisualization';
import WordCloudVisualization from './components/wordCloudVisualization';

// Wrapper components that use context
const TreeVisualizationWrapper = () => {
  const { selections } = usePartnership();
  return (
    <div className="max-h-[100%] w-full">
      <NewTreeVisualization selections={selections.map(s => s.behavior)} />
    </div>
  );
};

const WordCloudVisualizationWrapper = () => {
  return (
    <div className="min-h-screen w-full">
      <WordCloudVisualization />
    </div>
  );
};

function App() {
  return (
    <PartnershipProvider>
      <Router>
        <Routes>
          <Route path="/" element={<PartnershipApp />} />
          <Route path="/tree" element={<TreeVisualizationWrapper />} />
          <Route path="/wordcloud" element={<WordCloudVisualizationWrapper />} />
        </Routes>
      </Router>
    </PartnershipProvider>
  );
}

export default App;