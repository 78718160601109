import { PartnershipBehavior } from '../types/partnership';

export const partnershipBehaviors: PartnershipBehavior[] = [
  { 
    id: 1, 
    text: "Trust", 
    color: "#0F8CA2", 
    position: { x: '50%', y: '20%' }
  },
  { 
    id: 2, 
    text: "Connectivity", 
    color: "#0F54F2", 
    position: { x: '20%', y: '45%' }
  },
  { 
    id: 3, 
    text: "Culture", 
    color: "#10B3AB", 
    position: { x: '80%', y: '45%' }
  },
  { 
    id: 4, 
    text: "Community", 
    color: "#497B47", 
    position: { x: '30%', y: '75%' }
  },
  { 
    id: 5, 
    text: "Stewardship", 
    color: "#8DC059", 
    position: { x: '70%', y: '75%' }
  }
];