import React, { useState, useEffect, useRef, useMemo } from 'react';
import { PartnershipBehavior } from '../types/partnership';
import starryBackground from '../assets/1-1.png';
import { ReactComponent as MultiColor1250TreeSVG } from '../assets/final-form-tree-v2.svg';

interface NewTreeVisualizationProps {
  selections: PartnershipBehavior[];
}

interface LeafInstanceState {
  layerId: string;
  instancesShown: number[];
  totalInstances: number;
  lastShownInstance: number | null;
}

const behaviorToLayer = {
  1: 'Red_Layer',
  2: 'Purple_Layer',
  3: 'Blue_Layer',
  4: 'Orange_Layer',
  5: 'Yellow_Layer'
};

const NewTreeVisualization: React.FC<NewTreeVisualizationProps> = ({ selections }) => {
  const [leafStates, setLeafStates] = useState<Record<string, LeafInstanceState>>({});
  const [isInitialized, setIsInitialized] = useState(false);
  const [processedCount, setProcessedCount] = useState(0);
  const svgRef = useRef<SVGSVGElement>(null);
  const [lastAnimatedLeaf, setLastAnimatedLeaf] = useState<{layerId: string, instanceId: number} | null>(null);
  const [data, setData] = useState<PartnershipBehavior[]>([]);

  useEffect(() => {
    const eventSource = new EventSource(`${process.env.REACT_APP_GPS_TREE}/api/stream`);

    eventSource.onmessage = (event) => {
      try {
        const newData = JSON.parse(event?.data);
        setData((prevData) => [...prevData, ...newData]);
      } catch (error) {
        console.error('Error processing stream data:', error);
      }
    };

    eventSource.addEventListener('initial', (event) => {
      try {
        const initialData = JSON.parse(event?.data);
        setData(initialData);
      } catch (error) {
        console.error('Error processing initial data:', error);
      }
    });

    eventSource.addEventListener('entry', (event) => {
      try {
        const newEntry = JSON.parse(event?.data);
        setData((prevData) => [...prevData, newEntry]);
      } catch (error) {
        console.error('Error processing entry:', error);
      }
    });

    eventSource.onerror = (error) => {
      console.error('Stream error:', error);
      eventSource.close();
    };

    return () => eventSource.close();
  }, []);

  const processedSelections = useMemo(() => {
    return data && data.length > 0 ? data.map((s: any) => s?.behavior) : null;
  }, [data]);

  const allSelections = processedSelections ?? selections;

  const leafAnimations = `
    @keyframes leaf-appear {
      0% { 
        transform: scale(0) rotate(0deg);
        opacity: 0;
      }
      50% { 
        transform: scale(1.2) rotate(10deg);
        opacity: 1;
      }
      75% { 
        transform: scale(0.9) rotate(-5deg);
      }
      100% { 
        transform: scale(1) rotate(0deg);
      }
    }

    @keyframes leaf-sway {
      0% { transform: rotate(0deg) }
      25% { transform: rotate(3deg) }
      75% { transform: rotate(-3deg) }
      100% { transform: rotate(0deg) }
    }

      @keyframes leaf-sway-primary {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(3deg); }
    75% { transform: rotate(-3deg); }
    100% { transform: rotate(0deg); }
  }

  @keyframes leaf-sway-secondary {
    0% { transform: translate(0, 0); }
    33% { transform: translate(2px, 1px); }
    66% { transform: translate(-2px, -1px); }
    100% { transform: translate(0, 0); }
  }
  
  @keyframes leaf-sway-tertiary {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
  }

  svg[id^="Layer"] {
    width: 85vw;
    max-height: calc(100vh - 161px);
  }

    .leaf-visible {
     animation: 
      leaf-sway-primary 4s ease-in-out infinite,
      leaf-sway-secondary 6s ease-in-out infinite;
      leaf-sway-tertiary 5s ease-in-out infinite;
    transform-origin: bottom center;
    transform-box: fill-box;
    }

    .leaf-appear {
       animation: 
      leaf-appear 2s ease-out forwards,
      leaf-sway-primary 3s ease-in-out infinite 2s,
      leaf-sway-secondary 5s ease-in-out infinite 2s;
      leaf-sway-tertiary 4s ease-in-out infinite;
    transform-origin: bottom center;
    transform-box: fill-box;
    }

    .leaf-appear-fallback {
      opacity: 1;
      transition: opacity 0.5s ease-in;
    }
  `;

  const headerStyles = `
  .header-container {
    text-align: center;
    margin-bottom: 2rem;
  }
  .header-content {
    border-radius: 1rem;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    padding: 2rem;
  }
  .header-title {
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .header-subtitle {
    font-size: 1.375rem;
    color: white;
  }

  @media (min-width: 1920px) {
    .header-title {
      gap: 0.8rem;
    }
  }

  @media (min-width: 3840px) {
    .header-title {
      gap: 1.2rem;
      font-size: 5rem;
    }
    .header-subtitle {
      font-size: 2.75rem;
    }
    .header-content {
      padding: 4rem;
    }
  }

  @media (min-width: 7680px) {
    .header-title {
      font-size: 10rem;
      gap: 2.2rem;
    }
    .header-subtitle {
      font-size: 5.5rem;
    }
    .header-content {
      padding: 8rem;
    }
  }
`;

  useEffect(() => {
    if (svgRef.current && !isInitialized) {
      const counts: Record<string, number> = {};
      let initializationSuccessful = true;
      console.log(svgRef.current?.getElementById('Red_Layer'));
      console.log("1")
      Object.values(behaviorToLayer).forEach(layerId => {console.log( svgRef.current?.getElementById(layerId))});
      Object.values(behaviorToLayer).forEach(layerId => {
        try {
          const leafLayer = svgRef.current?.getElementById(layerId);
          if (!leafLayer) {
            console.warn(`Layer ${layerId} not found during initialization`);
            initializationSuccessful = false;
            return;
          }
          const leaves = Array.from(leafLayer.getElementsByTagName('g'))
            .filter(g => g.id.includes('_Leaf_'));
          
          const leafCount = leaves.length;
          if (leafCount === 0) {
            console.warn(`No leaves found in layer ${layerId}`);
            initializationSuccessful = false;
            return;
          }
          counts[layerId] = leafCount;
        } catch (error) {
          console.error(`Error initializing layer ${layerId}:`, error);
          initializationSuccessful = false;
        }
      });

      if (!initializationSuccessful) {
        console.error('Tree initialization failed');
        return;
      }

      setLeafStates(
        Object.entries(counts).reduce((acc, [layerId, count]) => ({
          ...acc,
          [layerId]: {
            layerId,
            instancesShown: [],
            totalInstances: count,
            lastShownInstance: null
          }
        }), {})
      );
      setIsInitialized(true);
    }
  }, [isInitialized]);

  useEffect(() => {
    if (!isInitialized || processedCount >= allSelections.length) return;
    
    try {
      const currentSelection = allSelections[processedCount];
      const layerId = behaviorToLayer[currentSelection.id as keyof typeof behaviorToLayer];
      
      if (!layerId) {
        setProcessedCount(prev => prev + 1);
        return;
      }

      const leafState = leafStates[layerId];
      if (!leafState || leafState.instancesShown.length >= leafState.totalInstances) {
        setProcessedCount(prev => prev + 1);
        return;
      }

      const newLeafIndex = leafState.instancesShown.length;
      
      setLastAnimatedLeaf({ layerId, instanceId: newLeafIndex });
      
      setLeafStates(prev => ({
        ...prev,
        [layerId]: {
          ...prev[layerId],
          instancesShown: [...prev[layerId].instancesShown, newLeafIndex],
          lastShownInstance: newLeafIndex
        }
      }));

      setProcessedCount(prev => prev + 1);
    } catch (error) {
      console.error('Error processing selection:', error);
    }
  }, [allSelections, processedCount, isInitialized]);

  useEffect(() => {
    console.log('useEffect triggered');
    if (!svgRef.current || !isInitialized) return;
    
    const updateLeafVisibility = () => {
      try {
        console.log('Updating leaf visibility');
        Object.entries(leafStates).forEach(([layerId, state]) => {
          const leafLayer = svgRef.current?.getElementById(layerId);
          console.log({layerId, state, leafLayer});
          if (!leafLayer) return;

          const leaves = Array.from(leafLayer.getElementsByTagName('g'))
            .filter(g => g.id.includes('_Leaf_'));

          leaves.forEach((leaf, index) => {
            const element = leaf as SVGElement;
            const isShown = state.instancesShown.includes(index);
            
            if (isShown) {
              element.setAttribute('visibility', 'visible');
              
              if (lastAnimatedLeaf?.layerId === layerId && 
                  lastAnimatedLeaf?.instanceId === index) {
                // Remove any existing animations first
                element.classList.remove('leaf-visible');
                element.classList.remove('leaf-appear');
                // Force a reflow to restart the animation
                void element.getBoundingClientRect();
                // Add the appear animation
                element.classList.add('leaf-appear');
              } else {
                // Already visible leaves just get the sway animation
                if (!element.classList.contains('leaf-visible') && 
                    !element.classList.contains('leaf-appear')) {
                  element.classList.add('leaf-visible');
                }
              }
            } else {
              element.setAttribute('visibility', 'hidden');
              element.classList.remove('leaf-visible');
              element.classList.remove('leaf-appear');
            }
          });
        });
      } catch (error) {
        console.error('Error updating leaf visibility:', error);
      }
    };

    requestAnimationFrame(updateLeafVisibility);
  }, [leafStates, lastAnimatedLeaf, isInitialized, data]);

  return (
    <div 
      className="max-h- bg-cover bg-center bg-no-repeat overflow-hidden"
      style={{ 
        backgroundImage: `url(${starryBackground})`,
        minHeight: '100vh'
      }}
    >
      <style>{leafAnimations}</style>
      <style>{headerStyles}</style>
      <div className="absolute  inset-0  tree-container-height "></div>

      <div className="relative z-10 max-w-full mx-auto h-[100vh] flex flex-col justify-around">
      <div className="header-container">
        <div className="header-content">
          <h1 className="header-title">
            <div>
              <span className="text-white">OUR COLLECTIVE SENSE OF </span>
              <span className="text-[#A4D65E]">PARTNERSHIP</span>
            </div>
          </h1>
          <p className="header-subtitle text-white">
            Watch our Partnership grow through our Committments!
          </p>
        </div>
      </div>
        <div className="relative w-full mx-auto flex justify-center">
          <MultiColor1250TreeSVG ref={svgRef} />
        </div>
      </div>
    </div>
  );
};

export default NewTreeVisualization;