import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { PartnershipBehavior } from '../types/partnership';
import { partnershipBehaviors } from '../constants/partnershipBehaviors';
import backgroundImage from '../assets/nature-background.png';
import logo from '../assets/logo.png';

// Partnership descriptions
const partnershipDescriptions = {
  1: "Fostering an environment where decisions are made through open dialogue, consensus-building, and shared responsibility.",
  2: "Creating a foundation of mutual trust through transparency, reliability, and consistent follow-through on commitments.",
  3: "Aligning our goals and aspirations to create a unified direction that inspires and guides our collective efforts.",
  4: "Maintaining clear, honest, and proactive channels of communication that facilitate understanding and collaboration.",
  5: "Actively supporting and empowering each other's growth, success, and well-being in both challenges and achievements."
};

// Partnership specific commitments
const partnershipCommitments = {
  trust: [
    { id: 't1', text: "Drive authenticity with my partners and my teams" },
    { id: 't2', text: "Be as transparent as possible with my partners" },
    { id: 't3', text: "Do the right thing even when it's difficult" },
    { id: 't4', text: "Enhance our shared brand through quality and exemplary client service" }
  ],
  connectivity: [
    { id: 'c1', text: "Express empathy and support for fellow PPMDs" },
    { id: 'c2', text: "Actively engage with firm leaders, other PPMDs, and practitioners" },
    { id: 'c3', text: "Embody team player mentality" },
    { id: 'c4', text: "Attend and host local events" },
    { id: 'c5', text: "Express gratitude to my fellow partners" }
  ],
  culture: [
    { id: 'cu1', text: "Provide meaningful recognition to my colleagues" },
    { id: 'cu2', text: "Focus on my well-being and my Partners' well-being" },
    { id: 'cu3', text: "Drive exceptional results and foster a high-performance culture" },
    { id: 'cu4', text: "Put interest of our clients, firm, and people first" },
    { id: 'cu5', text: "Inspire and motivate others" }
  ],
  community: [
    { id: 'co1', text: "Consider firm brand and longevity in my everyday actions" },
    { id: 'co2', text: "Drive long-term value creation through exceptional leadership and client service" },
    { id: 'co3', text: "Grow and develop future PPMDs" }
  ],
  stewardship: [
    { id: 's1', text: "Actively support the PPMDs I represent as a CDA" },
    { id: 's2', text: "Prioritize using my time to mentor and develop the next generation of leaders" },
    { id: 's3', text: "Encourage fellow PPMDs to think long-term and build for the future" },
    { id: 's4', text: "Lead with green dot mindset" }
  ]
};


// Success Modal Component
const SuccessModal: React.FC<{ 
  isOpen: boolean; 
  onClose: () => void; 
}> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/20 backdrop-blur-sm flex items-center justify-center z-50">
      <motion.div 
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="bg-white/90 backdrop-blur-md rounded-3xl p-8 max-w-md w-full mx-4 shadow-xl"
      >
        <h2 className="text-2xl font-bold text-center mb-4">
          <span className="text-green-500">Thank you</span> for your commitment!
        </h2>
        <p className="text-gray-600 text-center mb-8">
          Your partnership commitment has been recorded. Together, we make our community stronger.
        </p>
        <div className="flex justify-center">
          <button
            onClick={onClose}
            className="bg-[#86bc25] text-white px-8 py-3 rounded-lg hover:bg-[#78a821] transition-colors"
          >
            Close
          </button>
        </div>
      </motion.div>
    </div>
  );
};

interface SelectionScreenProps {
  onSubmit: (email: string, behavior: PartnershipBehavior, commitment: string | { type: 'other', text: string }) => void;
}

const SelectionScreen: React.FC<SelectionScreenProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    email: '',
    behavior: null as PartnershipBehavior | null,
    selectedCommitment: null as string | { type: 'other', text: string } | null,
    otherCommitment: ''
  });
  
  const [emailError, setEmailError] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);

  const validateEmail = (email: string) => {
    if (!email) {
      setEmailError('Email is required');
      return false;
    }
    if (!email.toLowerCase().endsWith('@deloitte.com')) {
      setEmailError('Please use your Deloitte email address');
      return false;
    }
    setEmailError('');
    return true;
  };

  const getRelevantCommitments = () => {
    if (!formData.behavior) return [];
    const behaviorType = formData.behavior.text.toLowerCase();
    return partnershipCommitments[behaviorType as keyof typeof partnershipCommitments] || [];
  };

  const otherInputRef = useRef<HTMLTextAreaElement>(null);

// Update the handleCommitmentToggle function to include scrolling
const handleCommitmentToggle = (commitmentId: string) => {
  if (commitmentId === 'other') {
    setShowOtherInput(!showOtherInput);
    if (!showOtherInput) {
      setFormData(prev => ({
        ...prev,
        selectedCommitment: { type: 'other', text: '' }
      }));
      // Add setTimeout to ensure the textarea is rendered before scrolling
      setTimeout(() => {
        otherInputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);
    } else {
      setFormData(prev => ({
        ...prev,
        selectedCommitment: null,
        otherCommitment: ''
      }));
    }
    return;
  }

    setFormData(prev => ({
      ...prev,
      selectedCommitment: prev.selectedCommitment === commitmentId ? null : commitmentId,
      otherCommitment: ''
    }));
    setShowOtherInput(false);
  };

  const handleOtherCommitmentChange = (text: string) => {
    setFormData(prev => ({
      ...prev,
      selectedCommitment: { type: 'other', text },
      otherCommitment: text
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.behavior && formData.selectedCommitment && validateEmail(formData.email)) {
      onSubmit(formData.email, formData.behavior, formData.selectedCommitment);
      setShowSuccessModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    setFormData({
      email: '',
      behavior: null,
      selectedCommitment: null,
      otherCommitment: ''
    });
  };

  const isFormDisabled = !formData.email || 
                        !formData.behavior || 
                        !formData.selectedCommitment || 
                        !!emailError;

  return (
    <div className="h-screen w-full bg-cover bg-center relative" 
       style={{ backgroundImage: `url(${backgroundImage})` }}>
    <div className="h-full w-full p-6">
      <div className="max-w-4xl mx-auto h-full bg-white/70 backdrop-blur-md rounded-3xl flex flex-col">
        {/* Fixed Header */}
        <div className="p-8 pb-4">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold">
              <span className="text-green-500">SENSE OF </span>
              <span className="text-gray-900">PARTNERSHIP</span>
            </h1>
            <img 
              src={logo} 
              alt="Deloitte" 
              className="h-6 w-auto object-contain"
            />
          </div>
        </div>

        {/* Scrollable Content */}
        <form onSubmit={handleSubmit} className="flex flex-col flex-1 overflow-hidden mt-2">
          <div className="flex-1 overflow-y-auto px-8">
            <div className="space-y-6">
              {/* Email Input */}
              <div className="relative">
              <p className="text-gray-500 text-sm font-light mb-2">
                Your Deloitte Email ID (johndoe@deloitte.com)
                </p>
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => {
                    setFormData({ ...formData, email: e.target.value });
                    if (emailError) validateEmail(e.target.value);
                  }}
                  pattern=".+@deloitte.com"
                  placeholder="johndoe@deloitte.com"
                  className="w-full px-3 py-2.5 bg-white/50 backdrop-blur-sm border border-gray-200 rounded-lg focus:ring-1 focus:ring-green-500/20 focus:border-green-500 text-sm"
                />
                {emailError && (
                  <p className="text-red-500 text-xs mt-1">{emailError}</p>
                )}
              </div>

              {/* Partnership Selection */}
              <div className="space-y-3">
                <p className="text-gray-500 text-sm font-light">
                  What represents partnership to you?
                </p>
                <div className="space-y-2">
                  {partnershipBehaviors.map((behavior) => (
                    <motion.button
                      type="button"
                      key={behavior.id}
                      onClick={() => setFormData({ ...formData, behavior, selectedCommitment: '' })}
                      className={`
                        w-full p-2.5 rounded-lg flex items-start gap-2 transition-all text-left
                        ${formData.behavior?.id === behavior.id 
                          ? 'bg-white shadow-md border border-green-500' 
                          : 'bg-white/50 hover:bg-white/80 border border-gray-200'}
                      `}
                    >
                      <div 
                        className="w-2.5 h-2.5 rounded-full mt-1.5 flex-shrink-0"
                        style={{ backgroundColor: behavior.color }}
                      />
                      <div>
                        <span className="text-gray-800 text-sm font-medium">{behavior.text}</span>
                        <p className="text-xs text-gray-600 mt-0.5">
                          {partnershipDescriptions[behavior.id as keyof typeof partnershipDescriptions]}
                        </p>
                      </div>
                    </motion.button>
                  ))}
                </div>
              </div>

              {/* Dynamic Commitment Selection */}
              {formData.behavior && (
      <div className="space-y-3 mb-6">
        <p className="text-gray-500 text-sm font-light">
          What will you commit to support this choice?
        </p>
        <div className="space-y-2">
          {getRelevantCommitments().map((commitment) => (
            <motion.button
              type="button"
              key={commitment.id}
              onClick={() => handleCommitmentToggle(commitment.id)}
              className={`
                w-full p-2.5 rounded-lg flex items-start gap-2 transition-all text-left
                ${formData.selectedCommitment === commitment.id
                  ? 'bg-white shadow-md border border-green-500'
                  : 'bg-white/50 hover:bg-white/80 border border-gray-200'}
              `}
            >
              <span className="text-sm text-gray-800">{commitment.text}</span>
            </motion.button>
          ))}
          
          {/* Other option */}
          <motion.button
            type="button"
            onClick={() => handleCommitmentToggle('other')}
            className={`
              w-full p-2.5 rounded-lg flex items-start gap-2 transition-all text-left
              ${showOtherInput
                ? 'bg-white shadow-md border border-green-500'
                : 'bg-white/50 hover:bg-white/80 border border-gray-200'}
            `}
          >
            <span className="text-sm text-gray-800">Other</span>
          </motion.button>

{showOtherInput && (
  <textarea
    ref={otherInputRef}
    value={formData.otherCommitment}
    onChange={(e) => handleOtherCommitmentChange(e.target.value)}
    className="w-full p-3 rounded-lg border border-gray-200 bg-white/50 text-sm"
    placeholder="Enter your commitment..."
    rows={3}
  />
)}
        </div>
      </div>
    )}
            </div>
            </div>

            {/* Submit Button */}
            <div className="p-8 pt-4 border-t border-gray-200/50">
            <motion.button
              type="submit"
              disabled={isFormDisabled}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className={`w-full px-6 py-2.5 rounded-lg font-medium text-sm transition-colors mt-2
                ${isFormDisabled
                  ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  : 'bg-[#86bc25] text-white hover:bg-[#78a821]'}`}
            >
              Submit
            </motion.button>
          </div>
        </form>
      </div>
    </div>
    
    <SuccessModal 
      isOpen={showSuccessModal} 
      onClose={handleCloseModal} 
    />
  </div>
  );
};

export default SelectionScreen;