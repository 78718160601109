import React, { createContext, useContext, useState, useEffect } from 'react';
import { PartnershipBehavior } from '../types/partnership';

export interface CommitmentEntry {
  id: string;
  email: string;
  behavior: PartnershipBehavior;
  commitments: string | { type: 'other', text: string };
  intent?: string;
}

interface PartnershipContextType {
  selections: CommitmentEntry[];
  setSelections: React.Dispatch<React.SetStateAction<CommitmentEntry[]>>;
  isProcessing: boolean;
  setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>;
}

// Storage key for consistency
const STORAGE_KEY = 'partnershipSelections';
// Custom event name for cross-tab communication
const SYNC_EVENT = 'partnership-sync';

const PartnershipContext = createContext<PartnershipContextType | undefined>(undefined);

export const PartnershipProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Initialize from localStorage
  const [selections, setSelections] = useState<CommitmentEntry[]>(() => {
    try {
      const stored = localStorage.getItem(STORAGE_KEY);
      return stored ? JSON.parse(stored) : [];
    } catch (error) {
      console.error('Error reading from localStorage:', error);
      return [];
    }
  });
  
  const [isProcessing, setIsProcessing] = useState(false);

  // Save to localStorage whenever selections change
  useEffect(() => {
    try {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(selections));
      // Broadcast change to other tabs
      const event = new CustomEvent(SYNC_EVENT, { detail: selections });
      window.dispatchEvent(event);
    } catch (error) {
      console.error('Error saving to localStorage:', error);
    }
  }, [selections]);

  // Listen for changes from other tabs
  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === STORAGE_KEY && e.newValue) {
        try {
          const newSelections = JSON.parse(e.newValue);
          setSelections(newSelections);
        } catch (error) {
          console.error('Error parsing storage value:', error);
        }
      }
    };

    const handleSyncEvent = (e: CustomEvent<CommitmentEntry[]>) => {
      setSelections(e.detail);
    };

    // Add event listeners
    window.addEventListener('storage', handleStorageChange);
    window.addEventListener(SYNC_EVENT as any, handleSyncEvent as EventListener);

    // Cleanup
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener(SYNC_EVENT as any, handleSyncEvent as EventListener);
    };
  }, []);


  return (
    <PartnershipContext.Provider
      value={{
        selections,
        setSelections,
        isProcessing,
        setIsProcessing,
      }}
    >
      {children}
    </PartnershipContext.Provider>
  );
};

export const usePartnership = () => {
  const context = useContext(PartnershipContext);
  if (context === undefined) {
    throw new Error('usePartnership must be used within a PartnershipProvider');
  }
  return context;
};
